export const UPLOAD_FILE_METADATA_DESC = {
  image: ["Supported file types: PNG, JPG, JPEG, GIF", "SVG, PNG, JPG (max. 800x400px)"]
};

export const UPLOAD_FILE_EDITOR_DESC = {
  image: [
    "Supported file types: PNG, JPG, JPEG, GIF",
    "Maximum file amount: 10 images",
    "Maximum file size: 10MB each"
  ]
};

export const AI_SUMMARY_SUPPORT =
  "Enable AI summaries to automatically generate concise overviews of your article for quick reading.";
