import * as React from "react";
import AvaIcon from "../../../../assets/ava-icon.svg";
import { useAtom } from "jotai";
import { profileAtom } from "../../../../components/Settings/atom";
import SettingIcon from "../../../../assets/setting-icon.svg";
import TwygLogo from "../../../../assets/twyg-logo-icon.svg";
import EyeIcon from "../../../../assets/eye-icon.svg";
import EditSquareIcon from "../../../../assets/edit-square-icon.svg";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useNavigate } from "react-router-dom";

const RightBarComponent = () => {
  const [profile] = useAtom(profileAtom);
  const [editor] = useLexicalComposerContext();
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = React.useState(() => editor.isEditable());

  return (
    <div className="w-[85px] z-10 sticky top-0 bg-white right-0 h-[100vh] py-[20px] px-[10px] border-r flex flex-col justify-end items-center border-solid border-[#F2F3F7] !shadow-[0px_4px_4px_rgba(0,0,0,0.25)]">
      <div className="flex flex-col gap-6 items-center">
        <div className="bg-[#F2F3F7] rounded-full h-10 w-10 flex items-center justify-center cursor-pointer hover:opacity-80">
          <img
            src={isEditable ? EyeIcon : EditSquareIcon}
            onClick={() => {
              editor.setEditable(!editor.isEditable());
              setIsEditable(!isEditable);
            }}
            alt=""
          />
        </div>
        <div className="bg-[#F2F3F7] rounded-full h-10 w-10 flex items-center justify-center cursor-pointer hover:opacity-80">
          <img src={SettingIcon} alt="" />
        </div>
        <img
          src={profile.imgUrl || AvaIcon}
          alt="avatar"
          className="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full border border-solid border-[#E6E7EB] cursor-pointer hover:opacity-80"
        />
        <div className="flex flex-col items-center gap-2 hover:opacity-80">
          <img
            src={TwygLogo}
            alt="logo"
            className="h-8 2xl:h-12 cursor-pointer"
            onClick={() => navigate("/")}
          />
          <p className="text-[10px] !text-growth-green font-[800] whitespace-nowrap">
            Writer Studio
          </p>
        </div>
      </div>
    </div>
  );
};

export default RightBarComponent;
