import TopicLoading from "./topic-loading.component";

const ListTopic = ({ title, listTopic, loading }) => {
  return (
    <div>
      <h1 className="text-lg 2xl:text-xl !text-[#6A4E42] font-[700] mb-[16px] 2xl:mb-[24px]">
        {title}
      </h1>
      {loading && <TopicLoading />}
      <div className="w-[100%] flex flex-wrap items-center gap-[10px] 2xl:gap-[16px]">
        {listTopic?.map((item) => (
          <div
            key={item.id}
            className="px-[15px] py-[8.5px] bg-[#887168] rounded-[80px]"
          >
            <p className="!text-white font-[600] text-sm">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListTopic;
