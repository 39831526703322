import { useFormContext, useWatch } from "react-hook-form";
import { Text } from "@chakra-ui/react";

const FormInputCounter = (props) => {
  const { control } = useFormContext();
  const value = useWatch({ name: props.name, control });
  return (
    <props.component
      p={{ base: "12px 12px", "2xl": "17px 24px" }}
      name={props.name}
      placeholder={props.placeholder}
      label={props.label}
      bottomContentProps={{
        mt: "12px",
        display: "flex"
      }}
      isOptional={props.isOptional}
      bottomRightContent={
        <Text marginLeft={"auto"} fontSize={{ base: "xs", "2xl": "sm" }} color={"dim-gray"}>
          {String(value).length}/{props.maxLength}
        </Text>
      }
      {...props}
    />
  );
};

export default FormInputCounter;
