import { BrowserRouter as Router } from "react-router-dom";

import AppLayout from "./components/AppLayout";
import "./App.css";
import HeaderComponent from "./components/Header";
import { Helmet } from "react-helmet";
import { odeleLightTheme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Footer from "./components/Footer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  return (
    <Router>
      <Helmet>
        <link rel="icon" href="/favicon.png" />
        <meta http-equiv="Accept-CH" content="DPR, Viewport-Width, Width" />
      </Helmet>
      <HeaderComponent />
      <div>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={odeleLightTheme}>
            <AppLayout />
          </ChakraProvider>
        </QueryClientProvider>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
