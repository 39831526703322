const PageNotFound = () => {
  return (
    <div>
      <p>404 Error</p>
      <p> Page not found</p>
    </div>
  );
};

export default PageNotFound;
