import { extendTheme } from "@chakra-ui/react";
import { ContainerTheme } from "./container.theme.ts";
import { ButtonTheme } from "./button.theme.ts";
import { LinkTheme } from "./link.theme.ts";
import { InputTheme } from "./input.theme.ts";
import { BadgeTheme } from "./badge.theme.ts";
import { CloseButtonTheme } from "./close-button.theme.ts";
import { TextareaTheme } from "./textarea.theme.ts";
import { PinInputTheme } from "./pin-input.theme.ts";
import { SelectTheme } from "./select.theme.ts";
import { SwitchTheme } from "./switch.theme.ts";
import { CheckboxTheme } from "./checkbox.theme.ts";
import { RadioTheme } from "./radio.theme.ts";
import { TagTheme } from "./tag.theme.ts";
import { colors } from "./colors.theme.ts";

export const odeleLightTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "white",
        fontSize: "1rem",
        fontFamily: "Nunito"
      }
    }
  },
  colors,
  shadows: {
    outline: "0px 4px 4px 0px rgba(242, 243, 247, 0.50)",
    xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
  },
  fontSizes: {
    tn: "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "32px",
    "5xl": "48px",
    "6xl": "56px",
    "7xl": "88px",
    "8xl": "104px"
  },
  components: {
    Button: ButtonTheme,
    Container: ContainerTheme,
    Input: InputTheme,
    Link: LinkTheme,
    Badge: BadgeTheme,
    CloseButton: CloseButtonTheme,
    Textarea: TextareaTheme,
    PinInput: PinInputTheme,
    Select: SelectTheme,
    Switch: SwitchTheme,
    Checkbox: CheckboxTheme,
    Radio: RadioTheme,
    Tag: TagTheme
  }
});
