import { useEffect, useMemo, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import LogoTwygs from "../../assets/logo-twygs.svg";
import { getUserInfo } from "../../apis/twygs-detail";
import AvatarIcon from "../../assets/ava-icon.svg";
import AdvertiserIcon from "../../assets/advertiser-icon.svg";
import CreatorIcon from "../../assets/creator-icon.svg";
import SettingIcon from "../../assets/setting-icon.svg";
import LogOutIcon from "../../assets/logout-icon.svg";
import WriteIcon from "../../assets/write-icon.svg";
import WriteGreenIcon from "../../assets/write-green-icon.svg";
import MenuIcon from "../../assets/menu-icon.svg";
import { useClickOutside } from "../../hooks/useOutsideClick";
import { removeAllCookies } from "../../constants";
import { profileAtom } from "../Settings/atom";
import HeaderActionComponent from "./header-action.component";
import NotificationComponent from "./Notification/notification.component";
import CloseIcon from "../../assets/close-black-icon.svg";
import ArrowIcon from "../../assets/arrow-green-icon.svg";

const staging = process.env.REACT_APP_IS_STAGING;

const HeaderComponent = () => {
  const [profile, setProfile] = useAtom(profileAtom);
  const [open, setOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState({
    recommendedTopics: false,
    trendingNews: false,
    recommendedAuthors: false
  });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const listItem = useMemo(
    () => [
      // {
      //   name: "Twygs Ads",
      //   icon: AdvertiserIcon,
      //   onClick: () => {
      //     window.open(`${process.env.REACT_APP_ADVERTISER_URL}`, "_blank");
      //   },
      // },
      // {
      //   name: "Creator Studio",
      //   icon: CreatorIcon,
      //   onClick: () => {
      //     window.open(`${process.env.REACT_APP_CREATOR_URL}`, "_blank");
      //   },
      // },
      // {
      //   name: "Account Settings",
      //   icon: SettingIcon,
      //   onClick: () => {
      //     navigate("/account-settings");
      //   },
      // },
      {
        name: "Log Out",
        icon: LogOutIcon,
        onClick: () => {
          removeAllCookies();
          // localStorage.clear();
          navigate("/");
        }
      }
    ],
    [navigate]
  );

  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  const handleToggleProfileModal = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        const result = await getUserInfo();
        setProfile(result);
        localStorage.setItem("user", JSON.stringify(result));
      }
    };
    if (token) fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div
      className={`${pathname === "/" ? "" : "hidden"} flex flex-col fixed top-0 left-0 w-full z-50 font-nunito`}
    >
      <div className={`bg-white w-full md:border-b-[2px] border-solid border-[#E6E7EB]`}>
        <div className="m-auto px-[20px] md:px-[50px] 2xl:px-[100px] w-full py-2 md:py-4 flex items-center">
          <div className="flex items-center">
            <img
              src={openSidebar ? CloseIcon : MenuIcon}
              className="block md:!hidden mr-4 cursor-pointer"
              alt=""
              onClick={() => setOpenSidebar((prev) => !prev)}
            />
            <img
              src={LogoTwygs}
              alt="logo"
              className="h-8 2xl:h-12 cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p className="text-3xl ml-6 font-extrabold hidden md:!block tracking-tighter text-[#4CAF50]">
              Newsletter
            </p>
          </div>

          <div className="flex-1 hidden md:!block">
            <HeaderActionComponent />
          </div>
          {token && profile ? (
            <div
              className="flex items-center gap-2 md:gap-[20px] relative ml-auto"
              ref={dropdownRef}
            >
              <div
                onClick={() => navigate("/article/create")}
                className="cursor-pointer hidden md:!flex items-center gap-2 bg-growth-green px-[20px] py-[12px] text-center !rounded-[8px] !text-white text-sm 2xl:text-base font-black hover:opacity-80"
              >
                Write
                <img src={WriteIcon} alt="write" />
              </div>
              <div
                onClick={() => navigate("/article/create")}
                className="bg-[#F2F3F7] flex md:hidden rounded-full h-10 w-10 items-center justify-center cursor-pointer"
              >
                <img src={WriteGreenIcon} alt="" />
              </div>
              <NotificationComponent avatar={profile?.imgUrl || AvatarIcon} />
              <div className="bg-[#F2F3F7] rounded-full h-10 w-10 flex items-center justify-center cursor-pointer">
                <img src={SettingIcon} alt="" />
              </div>
              <img
                onClick={handleToggleProfileModal}
                src={profile?.imgUrl || AvatarIcon}
                alt="avatar"
                className="min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px] 2xl:min-w-[40px] 2xl:min-h-[40px] 2xl:max-w-[40px] 2xl:max-h-[40px] rounded-full border border-solid border-[#E6E7EB] cursor-pointer"
              />
              <div
                className={`${
                  open ? "flex" : "hidden"
                } absolute z-20 top-[50px] right-0 p-[18px] flex flex-col gap-[8px] bg-white w-[270px] rounded-[12px] border border-solid border-[#F2F3F7] shadow-[0px_8px_12px_0px_#80808026]`}
              >
                {listItem?.map((item, index) => (
                  <div
                    onClick={() => {
                      item.onClick();
                      setOpen(false);
                    }}
                    key={index}
                    className="hover:bg-[#A6D38D] hover:rounded-[8px] cursor-pointer flex items-center gap-[12px] px-[12px] py-[8px]"
                  >
                    <img src={item.icon} alt={item.icon} />
                    <p className="font-extrabold text-sm 2xl:text-base">{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <a
              href={`${process.env.REACT_APP_TWYGS_AUTH_URL}login`}
              target="_self"
              id="btn"
              className="cursor-pointer bg-growth-green px-[20px] py-[12px] text-center !rounded-[8px] !text-white text-sm 2xl:text-base font-black hover:opacity-80 ml-auto"
            >
              Log In
            </a>
          )}
        </div>
      </div>
      {openSidebar && (
        <div className="top-0 left-0 fixed block md:!hidden w-full h-full mt-[58px] bg-white z-[100]">
          {/* <HeaderActionComponent className="" /> */}
          <div className="p-4 flex items-center justify-between">
            <p className="font-extrabold">Recommended Topics</p>
            <img src={ArrowIcon} alt="" />
          </div>
          <div className="p-4 flex items-center justify-between">
            <p className="font-extrabold">Trending News</p>
            <img src={ArrowIcon} alt="" />
          </div>
          <div className="p-4 flex items-center justify-between">
            <p className="font-extrabold">Recommended Authors</p>
            <img src={ArrowIcon} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
