import { useEffect } from "react";
import { useState } from "react";
import { fetchListArticle, fetchMyReadingList } from "../apis/discovery.api";

export const useGetListArticleByFilter = (type, topic) => {
  const [loading, setLoading] = useState(true);
  const [listArticle, setListArticle] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 4,
    totalPage: 0
  });

  const handleLoadMore = () => {
    if (!loading && pagination.page < pagination.totalPage) {
      setLoading(true);
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page + 1
      }));
    }
  };

  useEffect(() => {
    const fetchArticle = async () => {
      let result = [];
      switch (type) {
        case "myReading":
          result = await fetchMyReadingList(pagination);
          break;
        case "trending":
          result = await fetchListArticle(pagination);
          break;
        default:
          return [];
      }
      if (result) {
        setListArticle((prevList) =>
          pagination.page === 1 ? result.data : [...prevList, ...result.data]
        );
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalPage: result.totalPage
        }));
      }
      setLoading(false);
    };
    fetchArticle();
  }, [type, pagination.page, topic]);

  return { loading, listArticle, handleLoadMore };
};
