import React, { useState } from "react";
import SearchIcon from "../../assets/search-icon.svg";
import CloseIcon from "../../assets/close-icon.svg";

const HeaderActionComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Expand the search if there's input
    if (value.length > 0) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  return (
    <div className="w-full max-w-[500px] fixed top-[18px] left-[50%] right-[50%] translate-x-[-50%]">
      <div
        className={`transition-all duration-300 ease-in-out border border-[#4CAF50] bg-white rounded-[40px] p-2.5`}
      >
        {/* Search Input and Icons */}
        <div className="flex items-center">
          <img src={SearchIcon} alt="Search" className="w-4 h-4 md:w-6 md:h-6" />
          <input
            className="flex-1 mx-4 text-sm 2xl:text-base font-semibold placeholder:text-sm border-none outline-none focus:outline-none"
            type="text"
            placeholder="Search for articles, authors, or topics"
            value={searchTerm}
            onChange={handleInputChange}
          />
          {searchTerm && (
            <img
              src={CloseIcon}
              alt="Close"
              className="w-4 h-4 cursor-pointer"
              onClick={() => {
                setSearchTerm("");
                setIsExpanded(false);
              }}
            />
          )}
        </div>

        {/* Accordion Content */}
        {isExpanded && (
          <div className="mt-4 transition-all duration-300 ease-in-out">
            <div className="mb-4">
              <h4 className="text-xs font-semibold text-gray-500">Authors</h4>
              <ul>
                <li className="flex items-center gap-2 py-2 cursor-pointer hover:bg-gray-100">
                  <span className="font-semibold">Jenny Odell</span>
                  <span className="text-green-500">✔</span>
                </li>
                <li className="flex items-center gap-2 py-2 cursor-pointer hover:bg-gray-100">
                  <span className="font-semibold">Maya Ramirez</span>
                </li>
                <li className="flex items-center gap-2 py-2 cursor-pointer hover:bg-gray-100">
                  <span className="font-semibold">Ethan Chen</span>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-xs font-semibold text-gray-500">Articles</h4>
              <ul>
                <li className="py-2 cursor-pointer hover:bg-gray-100">
                  <p className="font-semibold">
                    Say Goodbye to boring Chatbots by combining Structure
                  </p>
                </li>
                <li className="py-2 cursor-pointer hover:bg-gray-100">
                  <p className="font-semibold">
                    Revolutionizing Software Development: The Rise of AI-Powered Chatbots
                  </p>
                </li>
                <li className="py-2 cursor-pointer hover:bg-gray-100">
                  <p className="font-semibold">
                    Breaking Down Barriers: Chatbots as a Bridge Between Software Engineers
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderActionComponent;
