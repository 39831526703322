import AvatarDefault from "../../../../assets/ava-icon.svg";

const CardAuthor = ({ author }) => {
  return (
    <div className="flex items-start gap-[8px]">
      <img
        className="w-[60px] h-[60px] rounded-full object-cover"
        src={author?.avatar || AvatarDefault}
        alt=""
      />
      <div className="flex flex-col gap-[8px]">
        <p className="text-sm 2xl:text-base font-[600]">{author?.name}</p>
        <p className="text-sm 2xl:text-base italic">{author?.description}</p>
      </div>
      <button className="py-2 px-3 !bg-growth-green !text-[#FAFAFA] rounded-[8px] font-[700] text-sm ml-auto">
        Follow
      </button>
    </div>
  );
};

export default CardAuthor;
