import { useState, useEffect } from "react";
import GoogleAd from "../../GoogleAds/index.jsx";
import CardArticle from "./components/card-article.component.jsx";
import ListFilter from "./components/list-filter.component.jsx";
import CardArticleLoading from "./components/card-article-loading.component.jsx";
import { useGetListArticleByFilter } from "../../../hooks/useGetListArticleByFilter.jsx";

const LeftContentComponent = () => {
  const [selected, setSelected] = useState({
    type: "trending",
    topic: 1
  });

  const { loading, listArticle, handleLoadMore } = useGetListArticleByFilter(
    selected.type,
    selected.topic
  );

  const handleScroll = () => {
    const listRef = document.querySelector("#parent");
    if (listRef) {
      const { scrollTop, scrollHeight, clientHeight } = listRef;
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        handleLoadMore();
      }
    }
  };

  useEffect(() => {
    const listElement = document.querySelector("#parent");
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
      return () => {
        listElement.removeEventListener("scroll", handleScroll);
      };
    }
  });

  const renderContentWithAds = () => {
    const contentWithAds = [];
    listArticle.forEach((item, index) => {
      contentWithAds.push(<CardArticle key={`article-${index}`} index={index} item={item} />);
      if ((index + 1) % 4 === 0) {
        contentWithAds.push(
          <GoogleAd key={`ad-${index}`} slot="3329737237" className="my-custom-ad !z-10" />
        );
      }
    });
    return contentWithAds;
  };

  return (
    <div className="pb-[50px] 2xl:pb-[30px]">
      <ListFilter selected={selected} setSelected={setSelected} />
      <div className="mt-[30px] px-5 md:px-0 2xl:mt-[60px] md:pr-[20px] 2xl:pr-[30px] flex flex-col gap-[30px] 2xl:gap-[60px]">
        {renderContentWithAds()}
        {loading && [...Array(3)].map((_, i) => <CardArticleLoading key={i} index={i} />)}

        {listArticle.length === 0 && !loading && (
          <p className="text-center text-3xl font-[600]">No articles</p>
        )}
      </div>
    </div>
  );
};

export default LeftContentComponent;
