const TopicLoading = () => {
  return (
    <div>
      <div className="w-full flex items-center gap-[24px] animate-pulse">
        <div className="w-[40%] h-[40px] bg-gray-300 rounded-full"></div>
        <div className="w-[60%] h-[40px] bg-gray-300 rounded-full"></div>
      </div>
    </div>
  );
};

export default TopicLoading;
