import Button from "../../Button";
import ArrowLeftIcon from "../../../assets/arrow-left-bold-green-icon.svg";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { articlePayloadAtom } from "../atom";
import { useAtom } from "jotai";
import CardArticle from "../../DiscoveryComponent/LeftContentComponent/components/card-article.component";

const PreviewComponent = ({ setStep }) => {
  const [articlePayload] = useAtom(articlePayloadAtom);

  const handlePreviousStep = () => {
    setStep(2);
  };

  const handleNextStep = () => {
    setStep(4);
  };

  return (
    <div className="w-full mb-[120px] max-w-[1127px] h-[100vh] overflow-y-auto md:pr-4">
      <div className="mt-6 mb-8 text-center max-w-[318px] m-auto">
        <p className="text-2xl font-[800]">Article Feed Preview</p>
        <p className="!text-[#808080] mt-1">
          Preview and edit how the article will look like inside Twygs Newsletter feed
        </p>
      </div>
      <CardArticle item={articlePayload} />
      <div className="bg-white flex flex-col gap-3 md:flex-row justify-end border-t-[1px] border-solid border-[#E6E7EB] fixed left-0 bottom-0 py-[15px] md:py-[20px] px-[15px] md:px-[40px] w-full">
        <Button variant="secondary" className="md:mr-auto" onClick={handlePreviousStep}>
          <img src={ArrowLeftIcon} alt="" className="mr-2" />
          Previous
        </Button>
        <Button onClick={handleNextStep}>
          Next
          <img src={ArrowRightIcon} alt="" className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

export default PreviewComponent;
