import { Spinner } from "@chakra-ui/react";

const Button = (props) => {
  const variants = {
    primary: "bg-growth-green !text-white",
    secondary: "bg-[#EDF7EE] !text-[#204A22] border-[1px] border-[#C8E6C9]",
    outline: "bg-white !text-growth-green border-[2px] border-growth-green",
    disable: "bg-white border border-[#F2F3F7] !text-[#CCCCCC] shadow-button-disabled"
  };

  const { children, className, disabled, variant = "primary", isLoading, ...rest } = props;

  return (
    <button
      {...rest}
      className={`${className || ""} ${variants[disabled ? "disable" : variant]} hover:opacity-80 px-5 py-[10px] flex items-center justify-center h-[42px] rounded-[8px] font-[900] ${disabled ? "cursor-not-allowed" : ""}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Spinner color="#ccc" size="sm" /> : children}
    </button>
  );
};

export default Button;
