import EssayIcon from "../assets/essay-icon.svg";
import InterviewIcon from "../assets/interview-icon.svg";
import ReviewIcon from "../assets/review-icon.svg";
import GuileIcon from "../assets/guide-icon.svg";
import AnnouncementIcon from "../assets/announcement-icon.svg";
import NewsIcon from "../assets/news-icon.svg";
import InvestigationIcon from "../assets/investigation-icon.svg";
import ProfileIcon from "../assets/profile-icon.svg";
import ListicleIcon from "../assets/listicle-icon.svg";
import FeatureIcon from "../assets/feature-icon.svg";
// import OpEdIcon from "../assets/oped-icon.svg";
// import ExplanatoryIcon from "../assets/explanation/explanation-icon.svg";

export const CATEGORY_LIST_ICON = [
  {
    id: 1,
    icon: InvestigationIcon
  },
  {
    id: 2,
    icon: FeatureIcon
  },
  {
    id: 3
  },
  {
    id: 4,
    icon: EssayIcon
  },
  {
    id: 5,
    icon: ProfileIcon
  },
  {
    id: 6,
    icon: EssayIcon
  },
  {
    id: 7,
    icon: ListicleIcon
  },
  {
    id: 8,
    icon: InterviewIcon
  },
  {
    id: 9,
    icon: ReviewIcon
  },
  {
    id: 10
  },
  {
    id: 11,
    icon: NewsIcon
  },
  {
    id: 12,
    icon: AnnouncementIcon
  },
  {
    id: 13,
    icon: GuileIcon
  }
];
