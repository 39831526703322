import axiosInstance from "./axios-instance";

export const fetchListArticle = async (pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles?limit=${pagination.limit}&page=${pagination.page}`
  );
  return result.data.data;
};

export const fetchMyReadingList = async (pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/my-articles?limit=${pagination.limit}&page=${pagination.page}`
  );
  return result.data.data;
};

export const fetchListTopic = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/topics`);
  return result.data.data;
};
