import { useState } from "react";
import { Helmet } from "react-helmet";
import Stepper from "../../components/Stepper/stepper.component";
import ChooseTemplateComponent from "../../components/CreateArticle/components/choose-template.component";
import EnterMetadataComponent from "../../components/CreateArticle/components/enter-metadata.component";
import PreviewComponent from "../../components/CreateArticle/components/preview.component";
import WriterEditor from "../../routes/WriterEditor";

const CreateArticle = () => {
  const [step, setStep] = useState(1);

  return (
    <div>
      <Helmet>
        <title>New Article</title>
      </Helmet>
      <div
        className={`w-full ${step === 4 ? "hidden" : ""} flex flex-col items-center px-[17.5px] pt-5 m-auto bg-[#FAFAFA]`}
      >
        <h1 className="text-[32px] font-[800] mb-4">Write New Article</h1>
        <Stepper step={step} setStep={setStep} />
        {step === 1 && <ChooseTemplateComponent step={step} setStep={setStep} />}
        {step === 2 && <EnterMetadataComponent step={step} setStep={setStep} />}
        {step === 3 && <PreviewComponent step={step} setStep={setStep} />}
      </div>
      {step === 4 && <WriterEditor></WriterEditor>}
    </div>
  );
};

export default CreateArticle;
