import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { useAtom } from "jotai";
import { contributorAtom } from "./atom";
import Cookies from "js-cookie";
import { FieldHookForm } from "../hook-form/field-hook-form";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useDisclosure
} from "@chakra-ui/react";
import ButtonCustom from "../../../Button";
import AvatarIcon from "../../../../assets/ava-icon.svg";
import XIcon from "../../../../assets/x-green-icon.svg";
import DeleteIcon from "../../../../assets/bin-red-icon.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/arrow-down-icon.svg";
import { fetchListRole } from "../../../../apis/article.api";

export const GET_CONTRIBUTORS = `${process.env.REACT_APP_TWYGS_API_URL}user` || "";

const TextAreaCustomContributor = (props) => {
  const {
    description,
    label,
    name,
    fontSizeError,
    isHiddenBottomContent,
    bottomContentProps,
    isHiddenError,
    bottomRightContent,
    isOptional,
    colorDes,
    placeholder
  } = props;

  const { control, setValue } = useFormContext();
  const { isOpen } = useDisclosure();

  const [contributors, setContributors] = useAtom(contributorAtom);
  const [selectedUser, setSelectedUser] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(""); // To store selected role
  const [loading, setLoading] = useState(false); // Added loading state

  const __watch_value = useWatch({
    name,
    control,
    defaultValue: ""
  });

  const [debouncedValue] = useDebounce(__watch_value, 500);

  useEffect(() => {
    const searchForContributors = async () => {
      if (debouncedValue) {
        setLoading(true);
        try {
          const apiUrl = `${GET_CONTRIBUTORS}?page=1&limit=1000&search=${encodeURIComponent(debouncedValue)}`;
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${Cookies.get(
                `${process.env.NEXT_PUBLIC_IS_STAGING ? "staging" : ""}token`
              )}`,
              "Content-Type": "application/json"
            }
          });
          const data = await response.json();
          setSuggestions(data?.data?.data);
        } catch (error) {
          console.error("Error searching for contributors:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setSuggestions([]);
      }
    };
    searchForContributors();
  }, [debouncedValue]);

  useEffect(() => {
    const getListRole = async () => {
      setLoading(true);
      try {
        const res = await fetchListRole();
        if (res) {
          setListRole(res);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getListRole();
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setValue(name, newValue);
  };

  // Handle selecting a contributor
  const handleSelectContributor = (contributor) => {
    setSelectedUser((prevSelectedUser) => [...prevSelectedUser, contributor]);
    setValue(name, ""); // Clear input
    setSuggestions([]); // Close suggestions dropdown
  };

  const handleAddContributor = (e) => {
    e.preventDefault();
    if (selectedUser?.length > 0 && selectedRole) {
      const newContributors = selectedUser.map((user) => ({
        user: {
          id: user.id,
          imgUrl: user.imgUrl,
          firstName: user.firstName,
          lastName: user.lastName
        },
        role: selectedRole
      }));

      setContributors((prevContributors) => [...prevContributors, ...newContributors]);
      setSelectedUser([]); // Clear selected users
      setSelectedRole("Role"); // Reset role after adding
    }
  };

  // Handle removing a contributor
  const handleRemoveContributor = (index) => {
    setSelectedUser(selectedUser.filter((_, i) => i !== index));
  };

  const handleRemoveListContributor = (index) => {
    setContributors(contributors.filter((_, i) => i !== index));
  };

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
  };

  const handleRoleSelectionForEachUser = (role, index) => {
    setContributors((prevContributors) =>
      prevContributors.map((contributor, i) =>
        i === index ? { ...contributor, role } : contributor
      )
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldHookForm
          name={name}
          description={description}
          label={label}
          fontSizeError={fontSizeError}
          isHiddenBottomContent={isHiddenBottomContent}
          bottomContentProps={bottomContentProps}
          isHiddenError={isHiddenError}
          isOptional={isOptional}
          bottomRightContent={bottomRightContent}
          colorDes={colorDes}
        >
          <div className="flex items-start">
            {/* Parent container with relative positioning */}
            <div className="w-full ">
              <div className="w-full relative border border-solid border-[#D3D3D3] rounded-[12px] min-h-auto px-[16px] md:px-[24px] py-[15px] flex gap-2 items-start justify-between">
                <div className="flex flex-wrap gap-2 items-center flex-grow self-center">
                  {selectedUser?.map((item, index) => (
                    <div
                      key={index}
                      className="py-1 px-[6px] border border-solid border-[#E9E9E9] rounded-[5px] text-sm leading-[19px] bg-white flex items-center gap-1"
                    >
                      {item.firstName} {item.lastName}
                      <img
                        className="cursor-pointer"
                        src={XIcon}
                        onClick={() => handleRemoveContributor(index)}
                        alt=""
                      />
                    </div>
                  ))}
                  <input
                    value={__watch_value}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    className="outline-none flex-grow w-auto text-sm 2xl:text-base placeholder-[#808080] bg-[#FAFAFA] truncate"
                  />
                </div>

                <Menu>
                  <MenuButton
                    as={Button}
                    bgColor={"#FAFAFA"}
                    outline={"none"}
                    border={"none"}
                    zIndex={"20"}
                    boxShadow={"none"}
                    height={"24px"}
                    fontSize={{ base: "sm", "2xl": "md" }}
                    fontWeight={"400"}
                    px={"4px"}
                    maxW={"max-content"}
                    _hover={{ bgColor: "none" }}
                    rightIcon={
                      <ArrowDownIcon
                        className={`w-[20px] h-[20px] transition duration-300 ${isOpen ? "transform rotate-180" : "transform rotate-0"}`}
                        alt=""
                      />
                    }
                  >
                    {selectedRole || "Role"}
                  </MenuButton>
                  <MenuList
                    p={"0.625rem"}
                    shadow={"md"}
                    border={"1px solid light-gray"}
                    borderRadius={"8px"}
                    display={"flex"}
                    flexDir={"column"}
                    gap={"4px"}
                    minW={"160px"}
                    maxH={"250px"}
                    fontSize={{ base: "sm", "2xl": "md" }}
                    overflow={"auto"}
                    css={{
                      "&::-webkit-scrollbar": { width: "6px" },
                      "&::-webkit-scrollbar-track": { width: "6px" },
                      "&::-webkit-scrollbar-thumb": { background: "#c6ceda", borderRadius: "20px" }
                    }}
                  >
                    {listRole?.map((role) => (
                      <MenuItem
                        key={role.id}
                        _hover={{ bgColor: "anti-flash-white" }}
                        rounded={"0.5rem"}
                        py={"8px"}
                        px={"10px"}
                        _focus={{ bgColor: "anti-flash-white" }}
                        fontSize={"0.875rem"}
                        lineHeight={"1.25rem"}
                        fontWeight={"800"}
                        onClick={() => handleRoleSelection(role.name)} // Call role selection function
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>

                {/* Updated absolute positioning for the suggestion list */}
                {loading ? (
                  <div
                    className="absolute w-full left-0 top-full mt-2 flex justify-center items-center bg-white border border-solid border-[#F2F3F7] rounded-[12px] p-4 z-10"
                    style={{ minHeight: "50px" }}
                  >
                    <Spinner size="md" color="green.500" />
                  </div>
                ) : (
                  suggestions?.length > 0 && (
                    <div className="absolute w-full left-0 top-full mt-2 flex flex-col gap-3 bg-white border border-solid border-[#F2F3F7] rounded-[12px] max-h-[228px] overflow-y-auto p-4 z-10">
                      {suggestions?.map((item) => (
                        <div
                          key={item?.id}
                          className="py-1 px-[6px] rounded-[5px] hover:bg-[#E6E7EB] text-sm leading-[19px] bg-white flex items-center gap-2 cursor-pointer"
                          onClick={() => handleSelectContributor(item)}
                        >
                          <img
                            className="w-[24px] h-[24px] rounded-full"
                            src={item?.imgUrl || AvatarIcon}
                            alt=""
                          />
                          <span>
                            {item?.firstName} {item?.lastName}
                          </span>
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
              {/* List of selected contributors */}
              <div className="mt-6 flex flex-col gap-4 max-h-[184px] overflow-y-auto pr-2">
                {contributors?.map((contributor, index) => (
                  <div key={index} className="flex items-center justify-between rounded">
                    <div className="flex items-center gap-2">
                      <img
                        src={contributor?.user.imgUrl || AvatarIcon}
                        alt={`${contributor?.user.firstName} ${contributor?.user.lastName}`}
                        className="w-6 h-6 rounded-full"
                      />
                      <span className="font-[600] text-sm 2xl:text-base">
                        {contributor?.user.firstName} {contributor?.user.lastName}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Menu>
                        <MenuButton
                          as={Button}
                          bgColor={"#FAFAFA"}
                          outline={"none"}
                          border={"none"}
                          boxShadow={"none"}
                          height={"24px"}
                          fontSize={{ base: "sm", "2xl": "md" }}
                          fontWeight={"400"}
                          px={"4px"}
                          maxW={"max-content"}
                          _hover={{ bgColor: "none" }}
                          rightIcon={
                            <ArrowDownIcon
                              className={`w-[20px] h-[20px] transition duration-300 ${isOpen ? "transform rotate-180" : "transform rotate-0"}`}
                              alt=""
                            />
                          }
                        >
                          {contributor?.role || "Role"}
                        </MenuButton>
                        <MenuList
                          p={"0.625rem"}
                          shadow={"md"}
                          border={"1px solid light-gray"}
                          borderRadius={"8px"}
                          display={"flex"}
                          flexDir={"column"}
                          zIndex={99}
                          gap={"4px"}
                          minW={"160px"}
                          fontSize={{ base: "sm", "2xl": "md" }}
                          overflow={"auto"}
                          css={{
                            "&::-webkit-scrollbar": { width: "6px" },
                            "&::-webkit-scrollbar-track": { width: "6px" },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#c6ceda",
                              borderRadius: "20px"
                            }
                          }}
                        >
                          {listRole?.map((role) => (
                            <MenuItem
                              key={role.id}
                              _hover={{ bgColor: "anti-flash-white" }}
                              rounded={"0.5rem"}
                              py={"8px"}
                              px={"10px"}
                              _focus={{ bgColor: "anti-flash-white" }}
                              fontSize={"0.875rem"}
                              lineHeight={"1.25rem"}
                              fontWeight={"800"}
                              onClick={() => handleRoleSelectionForEachUser(role.name, index)} // Call role selection function
                            >
                              {role.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                      <img
                        src={DeleteIcon}
                        onClick={() => handleRemoveListContributor(index)}
                        className="cursor-pointer"
                        alt="Remove"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <ButtonCustom
              onClick={(e) => handleAddContributor(e)}
              disabled={selectedUser.length === 0 || !selectedRole}
              className="ml-1.5 md:ml-3 mt-[6px] h-[46px]"
              variant="primary"
            >
              Add
            </ButtonCustom>
          </div>
        </FieldHookForm>
      )}
    />
  );
};

export { TextAreaCustomContributor };
