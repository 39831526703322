import { Route, Routes, useLocation } from "react-router-dom";
import DiscoveryPage from "../../routes/Discovery";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NotificationSettings from "../../routes/NotificationSettings";
import CreateArticle from "../../routes/CreateArticle";
import PageNotFound from "../../routes/NotFound";
import Settings from "../../routes/Settings";
// import WriterEditor from "../../routes/WriterEditor";

const AppLayout = () => {
  const pathname = useLocation().pathname;
  return (
    <div
      id="explore-react"
      className={`explore-react ${pathname === "/" ? "mt-[56px] md:mt-[80px]" : ""}  max-w-[1920px] m-auto`}
    >
      <Routes>
        <Route path="/" element={<DiscoveryPage />} />
        <Route path="/article/create" element={<CreateArticle />} />
        {/* <Route path="/writer-editor" element={<WriterEditor />} /> */}
        <Route path="/account-settings" element={<Settings />} />
        <Route path="/notification-settings" element={<NotificationSettings />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
};

export default AppLayout;
