import { Helmet } from "react-helmet";
import LeftContentComponent from "../../components/DiscoveryComponent/LeftContentComponent";
import RightContentComponent from "../../components/DiscoveryComponent/RightContentComponent";

const DiscoveryPage = () => {
  return (
    <div className="h-full">
      <Helmet>
        <title>Discovery</title>
      </Helmet>
      <div
        id="parent"
        className="w-full flex px-0 md:px-[50px] 2xl:px-[100px] h-[calc(100vh-82px-52px)] overflow-y-scroll overflow-x-hidden border-b-[2px] border-solid border-[#E6E7EB]"
      >
        <div className="w-full md:w-[65%] 2xl:w-[70%]">
          <LeftContentComponent />
        </div>
        <div className="w-[2px] h-full bg-[#E6E7EB] sticky top-0"></div>
        <div className="hidden md:!flex md:w-[35%] 2xl:w-[30%]">
          <RightContentComponent />
        </div>
      </div>
    </div>
  );
};

export default DiscoveryPage;
