import CheckIcon from "../../assets/check-white-icon.svg";

const Stepper = ({ step }) => {
  const steps = [
    { id: 1, label: "Choose template" },
    { id: 2, label: "Enter Metadata" },
    { id: 3, label: "Preview" }
  ];

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex items-center gap-2">
        {steps.map((s, index) => (
          <div key={s.id} className="flex items-center gap-2">
            {/* Step Indicator */}
            <div
              className={`rounded-full ${
                step >= s.id ? "bg-[#4CAF50]" : "bg-[#CCCCCC]"
              } w-[24px] h-[24px] flex justify-center items-center text-xs !text-white`}
            >
              {step > s.id ? <img src={CheckIcon} alt="checked" /> : s.id}
            </div>

            {/* Step Label */}
            <p
              className={`font-[500] ${
                step >= s.id ? "!text-[#204A22]" : "!text-[#CCCCCC]"
              } hidden text-sm md:!block`}
            >
              {s.label}
            </p>

            {/* Line between steps */}
            {index < steps.length - 1 && (
              <div className="h-[1px] w-[100px] md:w-[24px] bg-[#A3A3A3]"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
