import CardAuthor from "./card-author.component";

const RecommendedAuthors = ({ listAuthor }) => {
  return (
    <div>
      <div className="flex items-center justify-between mb-[16px] 2xl:mb-[24px]">
        <h1 className="text-lg 2xl:text-xl !text-[#6A4E42] font-[700] ">
          Recommended Authors
        </h1>
        <p className="text-sm 2xl:text-base !text-growth-green font-[900]">
          View All
        </p>
      </div>
      <div className="flex flex-col gap-[24px]">
        {listAuthor.map((item) => (
          <CardAuthor key={item.id} author={item}></CardAuthor>
        ))}
      </div>
    </div>
  );
};

export default RecommendedAuthors;
