import { useEffect } from "react";
import { fetchListTopic } from "../../../apis/discovery.api";
import { useState } from "react";
import ListTopic from "./components/list-topic.component";
import RecommendedAuthors from "./components/recommended-authors.component";

const ListCardAuthor = [
  {
    id: 1,
    name: "Author 1",
    avatar: "https://picsum.photos/200/300",
    description: "Description 1"
  },
  {
    id: 2,
    name: "Author 2",
    avatar: "https://picsum.photos/200/300",
    description: "Description 2"
  },
  {
    id: 3,
    name: "Author 3",
    avatar: "https://picsum.photos/200/300",
    description: "Description 3"
  }
];

const RightContentComponent = () => {
  const [loading, setLoading] = useState(true);
  const [listRecommendedTopics, setListRecommendedTopics] = useState([]);
  const [listTrendingNews, setListTrendingNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchListTopic();
        if (result.length > 0) {
          setListRecommendedTopics(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchListTopic();
        if (result.length > 0) {
          setListTrendingNews(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full">
      <div className="h-[40px] md:h-[96px] bg-white sticky top-0 z-10"></div>
      <div className="pl-[20px] 2xl:pl-[30px] flex flex-col gap-[20px] w-full 2xl:gap-[30px] mt-[30px] 2xl:mt-[60px]">
        <ListTopic title="Recommended Topics" listTopic={listRecommendedTopics} loading={loading} />
        <ListTopic title="Trending News" listTopic={listTrendingNews} loading={loading} />
        <RecommendedAuthors title="Recommended Authors" listAuthor={ListCardAuthor} />
      </div>
    </div>
  );
};

export default RightContentComponent;
