import { useEffect, useState } from "react";
import { CATEGORY_LIST_ICON } from "../../../constants/article.constant";
import Button from "../../Button";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { useAtom } from "jotai";
import { articlePayloadAtom } from "../atom";
import { fetchListWrittingType } from "../../../apis/article.api";
import { Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ChooseTemplateComponent = ({ setStep }) => {
  const [articlePayload, setArticlePayload] = useAtom(articlePayloadAtom);
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState(articlePayload.writingTypeId || 1);
  const [listWringType, setListWritingtype] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleNextStep = (e) => {
    e.preventDefault();
    setArticlePayload({ ...articlePayload, writingTypeId: selectedType });
    setStep(2);
  };

  useEffect(() => {
    const fetchListWritingType = async () => {
      setIsLoading(true);
      try {
        const res = await fetchListWrittingType();
        if (res) {
          setListWritingtype(res);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchListWritingType();
  }, []);

  return (
    <div className="mb-[120px] max-w-[775px] overflow-y-auto max-h-[calc(100vh-200px)] md:pr-4">
      <div className="mt-6 mb-8 text-center">
        <p className="text-2xl font-[800]">Get started with a template</p>
        <p className="!text-[#808080] mt-1">Select a template to bring your ideas to life</p>
      </div>
      {isLoading ? (
        <div className="flex gap-3 md:gap-6 justify-center w-full overflow-hidden">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark-green"
            size="lg"
          />
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-3 md:gap-6">
          {listWringType.map((item, index) => (
            <div
              key={item.id}
              className={`${
                selectedType === item.id ? "bg-[#EDF7EE]" : "bg-none"
              } p-6 flex flex-col gap-3 items-center rounded-[16px] w-[167px] border-[2px] border-solid border-[#2D211C] text-center cursor-pointer`}
              onClick={() => {
                setSelectedType(item.id);
              }}
            >
              <img src={CATEGORY_LIST_ICON[index]?.icon} alt="" />
              <p className="text-lg font-[800]">{item.name}</p>
              <p className="text-xs font-[600]">{item.description}</p>
            </div>
          ))}
        </div>
      )}

      <div className="bg-white flex justify-end border-t-[1px] w-full border-solid border-[#E6E7EB] fixed left-0 bottom-0 py-[20px] px-[40px]">
        <Button variant="outline" className={`mr-4`} onClick={() => navigate("/")}>
          Discard
        </Button>

        <Button onClick={(e) => handleNextStep(e)}>
          Next
          <img src={ArrowRightIcon} alt="" className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

export default ChooseTemplateComponent;
