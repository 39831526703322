import { FormProvider, useForm, useWatch } from "react-hook-form";
import { InputHookForm } from "../../Common/Form/input-with-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { metadataSchema } from "./schema";
import { Switch, Text, Tooltip } from "@chakra-ui/react";
import { MenuHookForm } from "../../Common/Form/menu-with-hook-form";
import { ReactComponent as DesTooltipIcon } from "../../../assets/des-tooltip-icon.svg";
import { FILE_TYPES, MAX_FILE_SIZE_10 } from "../../../constants/image.constant";
import Button from "../../Button";
import { useEffect, useRef, useState } from "react";
import { ALLOWED_FILE_TYPES, getContentType } from "../../../constants";
import UploadIcon from "../../../assets/upload-green-icon.svg";
import ArrowLeftIcon from "../../../assets/arrow-left-bold-green-icon.svg";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { fetchListTopic } from "../../../apis/discovery.api";
import DropzoneTwygsComponent from "../../Common/dropzone-twygs.component";
import { articlePayloadAtom } from "../atom";
import { useAtom } from "jotai";
import { TextAreaCustomHashtag } from "../../Common/Form/textarea-custom-hook-form/textarea-hashtag.component";
import { contributorAtom, hashtagAtom } from "../../Common/Form/textarea-custom-hook-form/atom";
import { profileAtom } from "../../Settings/atom";
import { TextAreaCustomContributor } from "../../Common/Form/textarea-custom-hook-form/textarea-contributor.component";
import { TextAreaHookForm } from "../../Common/Form/textarea-hook-form";
import { postAssetUpload } from "../../../apis/article.api";
import axios from "axios";
import { AI_SUMMARY_SUPPORT, UPLOAD_FILE_METADATA_DESC } from "../../../constants/twygs.constant";
import FormInputCounter from "./InputCountCharacter/form-input-counter.component";

const EnterMetadataComponent = ({ setStep }) => {
  const [articlePayload, setArticlePayload] = useAtom(articlePayloadAtom);
  const [hashtag] = useAtom(hashtagAtom);
  const [profile] = useAtom(profileAtom);
  const [contributors] = useAtom(contributorAtom);

  const [fileImg, setFileImg] = useState(articlePayload.cover || "");
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [listTopic, setListTopic] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef();

  const methods = useForm({
    resolver: yupResolver(metadataSchema),
    defaultValues: articlePayload
  });

  const validateFiles = (file) => {
    let messageError = "";
    if (!ALLOWED_FILE_TYPES.includes(file?.type)) {
      messageError = "Please ensure your file type is PNG, JPG, JPEG or GIF";
    }

    if (file?.size > MAX_FILE_SIZE_10) {
      messageError = "Please ensure your file size is less than 10MB";
    }
    return messageError;
  };

  const handleUploadImage = (file) => {
    setFileImg("");
    const newFile = file[0];
    setError(validateFiles(newFile));
    if (newFile) {
      setSelectedFile(newFile);
      const fakeUploadedUrl = URL.createObjectURL(newFile); // Replace with real upload logic
      setFileImg(fakeUploadedUrl);
      // Store in global state
      setArticlePayload((prev) => ({
        ...prev,
        cover: fakeUploadedUrl
      }));
    }
  };
  const handleClickReUpload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleReUpload = (value) => {
    handleUploadImage([value.target.files[0]]);
  };

  const handlePreviousStep = () => {
    setStep(1);
  };

  const onSubmit = async (formValue) => {
    try {
      const formattedContributors = contributors.map((contributor) => ({
        id: contributor.user.id, // Extract user ID
        role: contributor.role // Extract role
      }));
      let contentKey = "";
      if (selectedFile) {
        setLoading(true);
        contentKey = await postAssetUpload({
          fileName: [selectedFile.name]
        });
        const url = contentKey?.[0]?.url;
        await axios.put(url, selectedFile, {
          headers: {
            "content-disposition": "inline",
            "Content-Type": getContentType(selectedFile?.name.toLowerCase()),
            "x-amz-acl": "public-read"
          }
        });
      }
      setArticlePayload((prev) => ({
        ...prev,
        ...formValue,
        hashtags: hashtag,
        imgUrl: profile.imgUrl,
        coverCloudKey: contentKey[0]?.key,
        nameCreator: `${profile.firstName} ${profile.lastName}`,
        contributors: formattedContributors
      }));
      setStep(3);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicsRes = await fetchListTopic();
        if (topicsRes) setListTopic(topicsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="mb-[90px] md:mb-[200px] w-full overflow-y-auto max-h-[calc(100vh-200px)] md:pr-4 max-w-[608px]">
      <div className="mt-6 mb-8 m-auto text-center max-w-[318px]">
        <p className="text-2xl font-[800] ">Enter Meta data</p>
        <p className="!text-[#808080] mt-1">
          Enter article key details and improve the discoverability of the article
        </p>
      </div>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 w-full">
              <FormInputCounter
                name="title"
                placeholder="Add a title"
                label="Article Title"
                maxLength={100}
                component={InputHookForm}
              />
              <FormInputCounter
                name="subTitle"
                placeholder="Add a sub title"
                label="Article Subtitle"
                isOptional
                maxLength={100}
                component={InputHookForm}
              />
              <FormInputCounter
                component={TextAreaHookForm}
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
                name="description"
                placeholder="Write description"
                label="Description"
                isOptional
                maxLength={2000}
              />
              <FormInputCounter
                name="organization"
                placeholder="Organization title"
                label="Organization"
                isOptional
                maxLength={100}
                component={InputHookForm}
              />
              <MenuHookForm
                name="topicIds"
                label="Categories"
                isOptional
                isShowCheckBox
                placeholder="Select categories"
                data={listTopic || []}
                defaultValue={articlePayload?.topicIds}
              />
              <TextAreaCustomHashtag
                p={{ base: "12px 12px", "2xl": "17px 24px" }}
                placeholder="Press enter after each tag"
                label="Tags"
                isOptional
                name="hashtags"
                minHeight={"58px"}
                isHiddenBottomContent
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
              />
              <div className="flex items-center">
                <p className="mr-1">Enable AI summaries</p>
                <Tooltip
                  rounded="0.75rem"
                  label={AI_SUMMARY_SUPPORT}
                  placement={"top-start"}
                  zIndex={0}
                  bg="#E6E7EB"
                  p={{ base: "10px", "2xl": "1rem" }}
                  left={{ base: "100px", md: "0" }}
                  color="main.100"
                  fontSize={{ base: "xs", "2xl": "sm" }}
                  isOpen={isTooltipOpen}
                  transform={{
                    base: "translatex(-55%) !important",
                    md: "none !important"
                  }}
                >
                  <DesTooltipIcon
                    cursor="pointer"
                    className="w-[20px] h-[20px] z-0"
                    alt=""
                    onMouseEnter={() => handleMouseEnter("description")}
                    onMouseLeave={() => handleMouseLeave("description")}
                    onClick={() => handleImageClick("description")}
                  />
                </Tooltip>
                <Switch
                  {...methods.register("isEnableAISummaries")}
                  className="ml-auto"
                  variant="light-green"
                  size="lg"
                />
              </div>
              <div>
                <div className="mb-6">
                  <h1>
                    Article image <span className="!text-[#808080]">(optional)</span>
                  </h1>
                  <p className="text-sm !text-[#808080]">
                    This image will be the article thumbnail and will also appear inside the article
                    .
                  </p>
                </div>

                {fileImg ? (
                  <div>
                    <div className="flex items-center text-sm mb-2">
                      <input
                        type="file"
                        id="file-input"
                        ref={fileInputRef}
                        onChange={handleReUpload}
                        hidden
                      />
                      <Button
                        variant="secondary"
                        className="!px-4"
                        onClick={(e) => handleClickReUpload(e)}
                      >
                        <img src={UploadIcon} className="mr-2" alt="Upload" />
                        Choose another file
                      </Button>
                    </div>
                    <img
                      src={fileImg}
                      className="md:max-w-[586px] md:min-w-[586px] md:max-h-[360px] md:min-h-[360px] rounded-[8px] object-cover"
                      alt="Preview"
                    />
                    {error && (
                      <Text className="!text-red-500 mt-2" fontSize={{ base: "xs", "2xl": "sm" }}>
                        {error}
                      </Text>
                    )}
                  </div>
                ) : (
                  <DropzoneTwygsComponent
                    isError={error}
                    acceptFileTypes={FILE_TYPES.twygsImage}
                    className="!h-[214px] md:!h-[360px]"
                    title={
                      <p className="!text-[#808080] text-sm">
                        <span className="!text-[#00A375] font-[900]">Click to upload file</span> or
                        drag and drop
                      </p>
                    }
                    notMutiple
                    validateFiles={handleUploadImage}
                    desc={UPLOAD_FILE_METADATA_DESC.image.map((item, index) => (
                      <Text key={index} fontWeight="400" color="dim-gray" fontSize="xs">
                        {item}
                      </Text>
                    ))}
                  />
                )}
              </div>
              <div>
                <div className="mb-[250px]">
                  <div className="flex place-items-end">
                    <TextAreaCustomContributor
                      p={{ base: "12px 12px", "2xl": "17px 24px" }}
                      minHeight={"58px"}
                      name="contributors"
                      label="Contributors"
                      isOptional
                      placeholder="Add names of article contributors"
                      isHiddenBottomContent
                      css={{
                        "&::-webkit-scrollbar": {
                          display: "none"
                        }
                      }}
                      // defaultValue={articlePayload?.topicIds}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white flex flex-col gap-3 md:flex-row justify-end border-t-[1px] border-solid border-[#E6E7EB] fixed left-0 bottom-0 z-10 py-[15px] md:py-[20px] px-[15px] md:px-[40px] w-full">
              <Button
                variant="secondary"
                className="md:mr-auto"
                onClick={() => handlePreviousStep()}
              >
                <img src={ArrowLeftIcon} alt="" className="mr-2" />
                Previous
              </Button>
              <Button type="submit" isLoading={loading}>
                Next
                <img src={ArrowRightIcon} alt="" className="ml-2" />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default EnterMetadataComponent;
