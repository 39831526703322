import { useEffect } from "react";
import { fetchListTopic } from "../../../../apis/discovery.api";
import { useState } from "react";

const filterListItem = [
  {
    name: "Trending",
    value: "trending"
  },
  {
    name: "Breaking News",
    value: "breaking"
  },
  {
    name: "Following",
    value: "following"
  },
  {
    name: "For You",
    value: "forYou"
  },
  {
    name: "My Reading List",
    value: "myReading"
  }
];

const ListFilter = ({ selected, setSelected }) => {
  const [listTopic, setListTopic] = useState([]);

  const handleSelect = (value) => {
    setSelected({ ...selected, type: value });
  };

  const handleTopicSelected = (value) => {
    setSelected({ ...selected, topic: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchListTopic();
        if (result) {
          setListTopic(result);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      className={`sticky ${selected.type === "myReading" ? "h-[120px] md:h-[144px]" : "h-[70px] md:h-[96px]"} px-5 md:px-0 flex flex-col gap-3 justify-end top-0 bg-white z-20`}
    >
      <div className="flex gap-[20px] pb-3 md:pb-0 overflow-x-auto w-full">
        {filterListItem.map((item, index) => (
          <div
            key={index}
            onClick={() => handleSelect(item.value)}
            className={`px-[12px] py-[4px] md:p-[12px] ${
              selected.type === item.value ? "bg-[#4CAF50] !text-white" : "!text-[#4CAF50]"
            } cursor-pointer border-[2px] border-solid border-[#4CAF50] rounded-[8px] text-sm font-extrabold whitespace-nowrap`}
          >
            {item.name}
          </div>
        ))}
      </div>
      {selected.type === "myReading" ? (
        <div className="flex gap-4 overflow-x-hidden">
          {listTopic?.map((item) => (
            <div
              key={item.id}
              className={`px-[14px] py-[8.5px] rounded-[80px] ${
                selected.topic === item.id
                  ? "bg-[#204A22] !text-white"
                  : "!text-[#204A22] bg-[#C8E6C9]"
              } text-sm font-[800] cursor-pointer whitespace-nowrap`}
              onClick={() => handleTopicSelected(item.id)}
            >
              {item.name}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ListFilter;
