import axiosInstance from "./axios-instance";

export const postAssetUpload = async (data) => {
  try {
    const result = await axiosInstance.post(`articles/presigned-upload-asset`, data);
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error uploading asset:", error);
    throw error;
  }
};

export const fetchListWrittingType = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/writing-types`
  );
  return result.data.data;
};

export const fetchListUser = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}user`);
  return result.data.data;
};

export const fetchListRole = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/roles`);
  return result.data.data;
};

export const publishArticle = async (data) => {
  const result = await axiosInstance.patch(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/publish`,
    data
  );
  return result.data.data;
};

export const saveAsDraft = async (data) => {
  const result = await axiosInstance.patch(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/save-draft`,
    data
  );
  return result.data.data;
};
